import barba from '@barba/core';
import ajaxForms from '../inits/ajax-forms';
import mobileHeaderObserve from '../inits/mobile-header-observe';
import { lazyload } from '../inits/lazyload';
import reveal from '../inits/reveal';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import navThemeObserver from '../inits/nav-theme-observer';
import scrollSpy from '../inits/scroll-spy';
import scrollToElement from '../inits/scroll-to-element';
import ScrollTrigger from 'gsap/ScrollTrigger';
import currentPageObserver from '../inits/current-page-observer';
import parallax from '../inits/parallax';

export function initBaseView() {
    barba.hooks.beforeEnter((data) => {
        ajaxForms.init(data?.next.container);
        mobileHeaderObserve.init(data?.next.container);
        navThemeObserver.init(data?.next.container);
        scrollSpy.init(data?.next.container);
        scrollToElement.init(data?.next.container);
        currentPageObserver.checkCur(data?.next.namespace);
        parallax.init(data?.next.container);
    });

    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }

        lazyload.update();
    });

    barba.hooks.afterEnter((data) => {
        reveal.init(data?.next.container);
        (window as any).ym?.(85296103, 'hit', data?.next.url.href, { referer: data?.current.url.href });

        const languageSwitcher = document.querySelector('.js-language-switch');
        const { pathname } = window.location;
        let newLink = '';
        if (pathname.includes('/en/')) {
            newLink = pathname.replace('/en/', '/');
        } else {
            newLink = `/en${pathname}`;
        }
        languageSwitcher?.setAttribute('href', newLink);
    });

    barba.hooks.beforeLeave((data) => {
        const scrollTriggers = ScrollTrigger.getAll();

        if (scrollTriggers && scrollTriggers.length > 0) {
            scrollTriggers.forEach((st) => {
                st.kill();
            });
        }

        ajaxForms.destroy(data?.current.container);
        mobileHeaderObserve.destroy(data?.current.container);
        reveal.destroy(data?.current.container);
        navThemeObserver.destroy(data?.current.container);
        scrollSpy.destroy(data?.current.container);
        scrollToElement.destroy(data?.current.container);
    });

    barba.hooks.leave(() => {
        const popups = Array.from(document.querySelectorAll<LitPopupElement>('app-lit-popup'));
        if (popups.length) {
            popups.forEach((popup) => {
                if (popup.instance.isOpen) {
                    popup.close();
                }
                popup.classList.remove('lit-popup--opened');
                popup.classList.remove('is-show');
            });
        }
    });

    barba.hooks.afterLeave((data) => {
        parallax.destroy(data?.current.container);
    });
}

import axios from 'axios';
import { triggerCustomEvent } from '../utils/trigger-events';

const NO_SCROLL_CLASS = 'no-scroll';

function init() {
    function createPreloader() {
        const preloader = document.querySelector('.js-preloader');
        const indicator = document.querySelector('.js-preloader-text');
        let loadedAssets = 0;
        let assets: number[] = [];
        let wasBodyLocked = false;
        document.body.classList.add(NO_SCROLL_CLASS);

        async function enter() {
            if (preloader) {
                wasBodyLocked = document.body.classList.contains('no-scroll');
                if (!wasBodyLocked) {
                    document.body.classList.remove(NO_SCROLL_CLASS);
                }
            }
        }

        function leave() {
            if (preloader) {
                preloader.classList.add('preloader--complete');
                setTimeout(() => {
                    preloader.classList.add('preloader--hidden');
                }, 800);
                if (!wasBodyLocked) {
                    document.body.classList.remove(NO_SCROLL_CLASS);
                }
            }
        }

        function setPercentIndicatorValue(value: number) {
            if (indicator) {
                if (!isNaN(value)) {
                    if (value !== Infinity) {
                        indicator.textContent = `${value}%`;
                    } else {
                        indicator.textContent = `100%`;
                    }
                }
            }
            if (value === 100) {
                assets = [];
                triggerCustomEvent(document, 'preloader.complete');
            } else if (value == Infinity) {
                assets = [];
                triggerCustomEvent(document, 'preloader.complete');
            }
        }

        async function loadAsset(
            url: string,
            index: number,
            amount: number,
            asset: HTMLImageElement | HTMLVideoElement,
        ) {
            try {
                if (asset instanceof HTMLVideoElement) {
                    if (asset.readyState === 4) {
                        loadedAssets += 100;
                        setPercentIndicatorValue(Math.round(loadedAssets / amount));
                    } else {
                        asset.addEventListener('canplay', () => {
                            loadedAssets += 100;
                            setPercentIndicatorValue(Math.round(loadedAssets / amount));
                        });
                    }
                } else {
                    await axios.get(url, {
                        onDownloadProgress: () => {
                            loadedAssets += 100;
                            setPercentIndicatorValue(Math.round(loadedAssets / amount));
                        },
                    });
                }
            } catch (err) {
                let percent = 0;
                assets[index] = 100;

                for (let i = 0; i < assets.length; i++) {
                    percent += assets[i];
                }

                setPercentIndicatorValue(Math.round(percent / amount));
            }
        }

        async function loadAssetsFromElement(element: Element | Document = document) {
            const images = Array.from(
                element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])[src]:not(:empty)'),
            );
            const videos = Array.from(
                element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not([loading="lazy"])'),
            );
            const allAssets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];
            triggerCustomEvent(document, 'preloader.begin');
            if (allAssets.length > 0) {
                await Promise.all([
                    ...allAssets.map((asset, i) => loadAsset(asset.currentSrc, i, allAssets.length, asset)),
                ]);
            } else {
                setPercentIndicatorValue(100);
            }
        }

        return { enter, leave, loadAssetsFromElement } as const;
    }

    const preloader = createPreloader();
    document.addEventListener('preloader.complete', () => {
        preloader.leave();
    });
    // Initial load
    preloader.loadAssetsFromElement();
}

export default { init };

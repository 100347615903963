import gsap from 'gsap';
import { CustomEase } from "gsap/CustomEase";

export default function pageTransition() {
    const tl = gsap.timeline();
    tl.to('.loading-screen', {
        duration: 0.6,
        scaleY: 1,
        y: '0%',
        ease: CustomEase.create("custom", "M0,0 C0.25,0.25 0,1 1,1 ")
    });
    tl.to('.loading-screen', {
        duration: 0.6,
        scaleY: 1,
        y: '100%',
        ease: CustomEase.create("custom", "M0,0 C0.25,0.25 0,1 1,1 "),
        delay: 0.15
    });
    tl.set('.loading-screen', {
        y: '-100%',
    });
}
let asideNav;
const commonRootMargin = '-50% 0% -50% 0%';
const callback = (entries) => {
    entries.forEach((entry) => {
        if (asideNav) {
            if (entry.isIntersecting) {
                entry.target.dataset.navTheme == 'light'
                    ? asideNav?.classList.add('light-theme')
                    : asideNav?.classList.remove('light-theme');
            }
        }
    });
};

const map = new WeakMap();

const commonObserver = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold: [0, 1] });

function init(container = document) {
    asideNav = container.querySelector('.js-aside-nav');
    const elements = Array.from(document.querySelectorAll('[data-nav-theme]'));

    elements.forEach((el) => {
        const rootMargin = el.dataset.rootMargin || commonRootMargin;
        const observer =
            rootMargin === commonRootMargin
                ? commonObserver
                : new IntersectionObserver(callback, { rootMargin, threshold: [0, 1] });

        observer.observe(el);
        map.set(el, observer);
    });
}

function destroy(container = document) {
    const elements = Array.from(container.querySelectorAll('[data-nav-theme]'));
    asideNav = null;
    elements.forEach((el) => {
        const observer = map.get(el);

        if (observer) {
            observer.unobserve(el);
        }
    });
}

export default { init, destroy };

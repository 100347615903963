const map = new WeakMap();

function init(container: HTMLElement | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('.js-reveal'));
    elements.forEach((element) => {
        const rootMargin = element.dataset.rootMargin || '0% -10% 0% -10%';
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry) => {
                    const entryTarget = entry.target as HTMLElement;
                    if (entry.isIntersecting) {
                        if (entryTarget.dataset.revealRepeat !== 'true') {
                            observer.unobserve(entryTarget);
                        }

                        entryTarget.classList.add('is-revealed');
                    }

                    if (entryTarget.dataset.revealRepeat === 'true') {
                        if (!entry.isIntersecting) {
                            entryTarget.classList.remove('is-revealed');
                        }
                    }
                });
            },
            {
                rootMargin,
                threshold: [0, 1],
            },
        );
        observer.observe(element);
        map.set(element, observer);
    });
}

function destroy(container: HTMLElement | Document = document) {
    const elements = Array.from(container.querySelectorAll('.js-reveal'));
    elements.forEach((element) => {
        const observer = map.get(element);

        if (observer) {
            observer.unobserve(element);
            map.delete(element);
        }
    });
}

const _module = { init, destroy };

export default _module;

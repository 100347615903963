const mobileHeader = document.querySelector('.js-mobile-header');
const header = document.querySelector('.js-header');
const commonRootMargin = '0px 0px 0px 0px';
const callback = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            mobileHeader?.classList.remove('is-show');
            header?.classList.add('is-show');
        } else {
            mobileHeader?.classList.add('is-show');
            header?.classList.remove('is-show');
        }
    });
};

const map = new WeakMap();

const commonObserver = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold: [0, 1] });

function init(container = document) {
    const trigger = container.querySelector('.js-section-first');
    const observer = commonObserver;
    if (trigger) {
        observer.observe(trigger);
        map.set(trigger, observer);
    }
}

function destroy(container = document) {
    const trigger = container.querySelector('.js-section-first');
    const observer = map.get(trigger);
    if (observer) {
        observer.unobserve(trigger);
    }
}

const _module = { init, destroy }

export default _module;

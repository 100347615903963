import gsap from 'gsap';
import pageTransition from '../animations/page-transition';
import { CustomEase } from 'gsap/CustomEase';
import timeout from '../utils/timeout';
import ScrollTrigger from 'gsap/ScrollTrigger';

function contentAnimation(next) {
    const duration = 0.3;
    const ease = CustomEase.create('custom', 'M0,0 C0.25,0.25 0,1 1,1 ');
    const header = document.querySelector('.a-header');
    const headerItems = document.querySelectorAll('.a-header-item');
    const burgerBtn = document.querySelectorAll('.a-burger');

    // Page variables
    const bgDecor = next.container.querySelector('.js-hero-decor');
    const logo = next.container.querySelector('.js-union-logo');
    const decors = next.container.querySelectorAll('.js-union-decor');
    const maskots = next.container.querySelectorAll('.js-union-maskot');
    const cans = next.container.querySelectorAll('.js-union-can');

    const tl = gsap.timeline({
        onComplete: () => {
            if (typeof onComplete === 'function') {
                onComplete();
            }
            tl.kill();
        },
    });

    tl.set(burgerBtn, {
        scaleX: 0,
    })
        .fromTo(
            header,
            {
                translateY: '-100%',
                duration,
                ease,
            },
            {
                translateY: 0,
                duration,
                ease,
            },
            0,
        )
        .to(headerItems, {
            translateY: 0,
            opacity: 1,
            duration,
            ease,
            stagger: 0.1,
        })
        .to(
            burgerBtn,
            {
                duration,
                scaleX: 1,
                ease,
            },
            0.8,
        )
        // Insert your page animations after this
        .from(
            bgDecor,
            {
                opacity: 0,
                y: 10,
                duration: 0.4,
                ease,
            },
            0.8,
        )
        .from(
            logo,
            {
                opacity: 0,
                y: 10,
                duration: 0.9,
                ease,
            },
            0.9,
        )
        .from(
            maskots,
            {
                opacity: 0,
                y: 40,
                scale: 0,
                duration: 0.9,
                ease,
            },
            1,
        )
        .from(
            cans,
            {
                opacity: 0,
                y: 10,
                duration: 0.9,
                ease,
            },
            1,
        )
        .from(
            decors,
            {
                opacity: 0,
                y: 5,
                scale: 0,
                duration: 0.9,
                ease,
            },
            1.1,
        );
}

export default {
    name: 'union page transition',
    to: {
        namespace: 'union-page',
    },

    async leave() {
        const done = this.async();

        pageTransition();
        window.scrollTo({ top: 0, behavior: 'auto' });
        await timeout(600);
        done();
    },

    async enter({ next }) {
        window.scrollTo({ top: 0, behavior: 'auto' });
        contentAnimation(next);
    },

    async after() {
        const scrollTriggers = ScrollTrigger.getAll();
        if (scrollTriggers && scrollTriggers.length > 0) {
            scrollTriggers.forEach((st) => {
                st.refresh();
            });
        }
    },

    async once({ next }) {
        window.scrollTo({ top: 0, behavior: 'auto' });
        contentAnimation(next);
    },
};

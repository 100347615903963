import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollToPlugin);

let asideNav;

function scrollToElement(event) {
    if (event) {
        event.preventDefault();
    }

    const id = this.dataset.scrolltoId;
    if (!id) {
        console.error('[scrollto] No id provided.');
        return;
    }
    // ScrollTrigger.refresh();
    const targetElement = document.getElementById(id);
    if (targetElement) {
        let scrollTarget;
        if (targetElement.dataset.calcScrollTop) {
            scrollTarget = targetElement.dataset.calcScrollTop;
        } else {
            scrollTarget = targetElement.offsetTop;
        }
        const distance = Math.abs(window.pageYOffset - scrollTarget);
        let k;
        if (distance > 10000) {
            k = 7000;
        } else if (distance > 5000) {
            k = 5000;
        } else if (distance > 3000) {
            k = 2500;
        } else if (distance > 1500) {
            k = 1500;
        } else {
            k = 1000;
        }
        const speed = distance / (k * (this.classList.contains('js-aside-nav-link') ? 1.7 : 1));
        gsap.to(window, {
            duration: speed,
            scrollTo: scrollTarget,
            ease: 'power2.easeOut',
            onComplete: () => {
                targetElement.dataset.navTheme == 'light'
                    ? asideNav?.classList.add('light-theme')
                    : asideNav?.classList.remove('light-theme');
            }
        });
    }
}

function init(container = document) {
    asideNav = container.querySelector('.js-aside-nav');
    const anchorLinks = Array.from(container.querySelectorAll('[data-scrollto-id]'));
    if (anchorLinks.length) {
        anchorLinks.forEach((link) => link.addEventListener('click', scrollToElement));
    }
}

function destroy(container = document) {
    asideNav = null;
    const anchorLinks = Array.from(container.querySelectorAll('[data-scrollto-id]'));
    if (anchorLinks.length) {
        anchorLinks.forEach((link) => link.removeEventListener('click', scrollToElement));
    }
}

const _module = { init, destroy };

export default _module;

import Parallax from 'parallax-js';

const map = new WeakMap();

function init(container = document) {
    const scenes = Array.from(container.querySelectorAll('.js-paralax-scene'));
    scenes.forEach((scene) => {
        const parallaxInstance = new Parallax(scene, {
            selector: '.decor img',
        });
        map.set(scene, parallaxInstance);
    });
}

function destroy(container = document) {
    const scenes = Array.from(container.querySelectorAll('.js-paralax-scene'));
    scenes.forEach((scene) => {
        const parallaxInstance = map.get(scene);

        if (parallaxInstance) {
            parallaxInstance.destroy();
        }
    });
}

function getInstanceByElement(el) {
    return el ? map.get(el) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;

const isMobile = window.matchMedia('(max-width: 1024px)').matches;

export default class Marquee3k {
    element: HTMLElement;

    speed: number;

    pausable: boolean;

    reverse: boolean;

    paused: boolean;

    isHovered: boolean;

    parent: HTMLElement;

    parentProps: DOMRect;

    content: HTMLElement;

    innerContent: string;

    wrapStyles: string;

    offset: number;

    wrapper: HTMLElement;

    contentWidth: number;

    requiredReps: number;

    rAF: number;

    previousWidth: number;

    timer: NodeJS.Timeout;

    constructor(element: HTMLElement, options: any) {
        this.animate = this.animate.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onMouseenter = this.onMouseenter.bind(this);
        this.onMouseleave = this.onMouseleave.bind(this);
        this.onMousedown = this.onMousedown.bind(this);
        this.onMousemove = this.onMousemove.bind(this);
        this.onMouseup = this.onMouseup.bind(this);

        this.element = element;
        // eslint-disable-next-line no-nested-ternary
        // this.speed = element.dataset.speed ? Number(element.dataset.speed) : isMobile ? 0.4 : 1.3;
        this.speed = options.speed || (isMobile ? 0.4 : 1.3);
        this.pausable = options.pausable || false;
        this.reverse = options.reverse || false;
        this.paused = false;
        this.isHovered = false;
        // this.parent = element.parentElement as HTMLElement;
        this.parent = element as HTMLElement;
        this.parentProps = this.parent.getBoundingClientRect();
        this.content = element.children[0] as HTMLElement;
        this.rAF = 0;

        this.innerContent = this.content.innerHTML;
        this.wrapStyles = '';
        this.offset = 0;

        this.content.classList.add('js-marquee__copy');
        this.content.style.display = 'inline-block';
        this.contentWidth = this.content.offsetWidth;

        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('marquee3k__wrapper');
        this.wrapper.style.whiteSpace = 'nowrap';

        this.wrapper.appendChild(this.content);
        this.element.appendChild(this.wrapper);

        this.requiredReps =
            this.contentWidth > this.parentProps.width
                ? 2
                : Math.ceil((this.parentProps.width - this.contentWidth) / this.contentWidth) + 1;

        for (let i = 0; i < this.requiredReps; i++) {
            this._createClone();
        }

        if (this.reverse) {
            this.offset = this.contentWidth * -1;
        }

        this.element.classList.add('is-init');

        this._setupEvents();

        this.previousWidth = this.parentProps.width;
        this.rAF = requestAnimationFrame(this.animate);
        window.addEventListener('resize', this.onResize);
        this.wrapper.style.whiteSpace = 'nowrap';
    }

    protected _setupEvents() {
        this.element.addEventListener('mouseenter', this.onMouseenter);
        this.element.addEventListener('mouseleave', this.onMouseleave);
        this.element.addEventListener('mousedown', this.onMousedown);
    }

    protected _createClone() {
        const clone = this.content.cloneNode(true) as HTMLElement;
        clone.style.display = 'inline-block';
        clone.classList.add('js-marquee__copy');
        this.wrapper.appendChild(clone);
    }

    protected render() {
        if (!this.paused) {
            const isScrolled = this.reverse ? this.offset < 0 : this.offset > -this.contentWidth;
            const direction = this.reverse ? -1 : 1;
            const reset = this.reverse ? -this.contentWidth : 0;

            if (isScrolled) {
                // if (!this.isHovered) {
                this.offset -= this.speed * direction;
                // } else {
                //     this.offset = this.savedOffset + (this.currentX - this.startX);
                // }
            } else {
                this.offset = reset;
            }

            this.wrapper.style.transform = `translate3d(${this.offset}px, 0, 0)`;
        }
    }

    repopulate(difference: number, isLarger: boolean) {
        this.contentWidth = this.content.getBoundingClientRect().width;

        if (isLarger) {
            const amount = Math.ceil(difference / this.contentWidth) + 1;

            for (let i = 0; i < amount; i++) {
                this._createClone();
            }
        }
    }

    protected animate() {
        this.render();
        this.rAF = requestAnimationFrame(this.animate);
    }

    destroy() {
        cancelAnimationFrame(this.rAF);
        window.removeEventListener('resize', this.onResize);
        this.element.removeEventListener('mouseenter', this.onMouseenter);
        this.element.removeEventListener('mouseleave', this.onMouseleave);
        this.element.removeEventListener('mousedown', this.onMousedown);
        document.removeEventListener('mousemove', this.onMousemove);
        document.removeEventListener('mouseup', this.onMouseup);
    }

    onResize() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.contentWidth = this.content.offsetWidth;

            if (this.reverse) {
                this.offset = this.contentWidth * -1;
            }

            const isLarger = this.previousWidth < this.parentProps.width;
            const difference = this.parentProps.width - this.previousWidth;
            this.repopulate(difference, isLarger);
            this.previousWidth = this.parentProps.width;
        }, 200);
    }

    onMouseenter() {
        this.isHovered = true;
        if (this.pausable) this.paused = true;
    }

    onMouseleave() {
        this.isHovered = false;
        if (this.pausable) this.paused = false;
    }

    onMousedown(event: any) {
        this.savedOffset = this.offset;
        this.startX = event.clientX;
        this.currentX = this.startX;
        document.addEventListener('mousemove', this.onMousemove);
        document.addEventListener('mouseup', this.onMouseup);
    }

    onMousemove(event: any) {
        this.currentX = event.clientX;
    }

    onMouseup() {
        document.removeEventListener('mousemove', this.onMousemove);
        document.removeEventListener('mouseup', this.onMouseup);
        this.savedOffset = this.offset;
        this.startX = 0;
        this.currentX = 0;
    }
}

import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

function init() {
    const requestPopup = document.querySelector<LitPopupElement>('[data-lit-popup="request"]');
    const blocks = Array.from(document.querySelectorAll<HTMLElement>('.js-form-popup-block'));
    const inputs = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-popup-option-input'));

    inputs.forEach((input) => {
        input.addEventListener('change', () => {
            blocks.forEach((el) => {
                el.classList.remove('is-active');
            });

            blocks
                .find((el) => el.dataset.contactsForm === input.value)
                ?.classList[input.checked ? 'add' : 'remove']('is-active');
        });
    });

    Array.from(document.querySelectorAll<HTMLElement>('[data-contacts-form-close]')).forEach((btn) => {
        btn.addEventListener('click', () => {
            const exactInput = inputs.find((input) => input.value === btn.dataset.contactsFormClose);

            if (exactInput) {
                exactInput.checked = false;
                exactInput.dispatchEvent(new Event('change'));
            }
        });
    });

    requestPopup?.instance.on('close-complete', () => {
        const checkedInput = inputs.find((input) => input.checked);

        if (checkedInput) {
            checkedInput.checked = false;
            checkedInput.dispatchEvent(new Event('change'));
        }
    });
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;

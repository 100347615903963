let asideNav;
let asideNavLinks;
const commonRootMargin = '-10% 0% -90% 0%';
const callback = (entries) => {
    entries.forEach((entry) => {
        if (asideNav) {
            if (entry.isIntersecting) {
                const sectionName = entry.target.id;
                if (asideNavLinks.length) {
                    asideNavLinks.forEach((link) => {
                        // eslint-disable-next-line max-len
                        link.dataset.scrolltoId === sectionName
                            ? link.classList.add('is-active')
                            : link.classList.remove('is-active');
                    });
                }
            }
        }
    });
};

const map = new WeakMap();

const commonObserver = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold: [0, 1] });

function init(container = document) {
    asideNav = container.querySelector('.js-aside-nav');
    if (asideNav) {
        asideNavLinks = Array.from(asideNav.querySelectorAll('.js-aside-nav-link'));
    }
    const elements = Array.from(container.querySelectorAll('.js-scroll-spy-section'));
    elements.forEach((el) => {
        const rootMargin = el.dataset.rootMargin || commonRootMargin;
        const observer =
            rootMargin === commonRootMargin
                ? commonObserver
                : new IntersectionObserver(callback, { rootMargin, threshold: [0, 1] });

        observer.observe(el);
        map.set(el, observer);
    });
}

function destroy(container = document) {
    asideNav = null;
    asideNavLinks = null;
    const elements = Array.from(container.querySelectorAll('.js-scroll-spy-section'));

    elements.forEach((el) => {
        const observer = map.get(el);

        if (observer) {
            observer.unobserve(el);
        }
    });
}

export default { init, destroy };

const links = document.querySelectorAll('.js-burger-link');

function checkCur(namespace) {
    links.forEach(link => {
        if (link.dataset.namespace === namespace) {
            link.classList.add('is-current');
        } else {
            link.classList.remove('is-current');
        }
    })
}

export default { checkCur }
import { LitElement, css, html, unsafeCSS } from 'lit';
import Uppy, { UppyFile } from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';

import _styles from './Upload.ce.scss';

export interface Upload {
    files: UppyFile<Record<string, unknown>, Record<string, unknown>>[];
    tempFilesEndpoint: string;
    uppy: Uppy;
    _input: HTMLInputElement | null;
    _isUploading: boolean;
}

// const dict = {
//     chooseFiles: 'Загрузить файл',
//     loading: 'Идет загрузка, подождите',
//     loadingError: 'Ошибка загрузки файла',
// };

/**
 * @attr {Boolean} multiple
 * @attr {String} name
 * @attr {String} tempFilesEndpoint
 *
 * @fires upload - Триггерится при начале загрузки файла.
 * @fires upload-success - Триггерится при успешной загрузке файла.
 * @fires complete - Триггерится при окончании загрузки файла (успешной или неудачной).
 *
 * @slot - Контент в формате HTML. Содержит (обязательно) <input type="file">.
 */
export class Upload extends LitElement {
    constructor() {
        super();
        this._onInputChange = this._onInputChange.bind(this);

        this.files = [];
        this._isUploading = false;
    }

    static get properties() {
        return {
            files: {
                type: Array,
            },
            tempFilesEndpoint: {
                type: String,
                attribute: 'temp-files-endpoint',
            },
            _isUploading: {
                type: Boolean,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        setTimeout(() => {
            this._input = this.querySelector<HTMLInputElement>('input[type="file"]');

            if (!this._input) {
                throw new Error('Input element not found');
            }

            this._input.classList.add('visually-hidden');

            this.uppy = new Uppy({
                pretty: false,
                autoProceed: true,
                // maxNumberOfFiles: 40,
                // allowedFileTypes: [
                //     'image/*',
                //     '.doc',
                //     '.docx',
                //     '.rtf',
                //     '.xls',
                //     '.xlsx',
                //     '.pdf',
                //     '.xml,application/msword',
                //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                // ],
            });

            this.uppy.use(XHRUpload, {
                endpoint: this.tempFilesEndpoint,
                formData: true,
                fieldName: this._input.name,
            });

            this.uppy.on('upload', (data) => {
                this._isUploading = true;
                this.dispatchEvent(new CustomEvent('upload', { detail: data, composed: true }));
            });

            this.uppy.on('upload-success', (file) => {
                this.files.push(file);
                this.dispatchEvent(new CustomEvent('upload-success', { detail: file, composed: true }));
            });

            this.uppy.on('complete', (data) => {
                this._isUploading = false;
                // this._input.value = '';
                this.dispatchEvent(new CustomEvent('complete', { detail: data, composed: true }));
            });

            this._input.addEventListener('change', this._onInputChange);
        }, 1);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this._input?.removeEventListener('change', this._onInputChange);
        this.uppy.cancelAll();
        this.uppy.close();
    }

    render() {
        return html`
            <div>
                ${this.files.length > 0
                    ? html`
                          <ul class="list">
                              ${this.files.map(
                                  (file) => html`
                                      <li class="list__item">
                                          <div class="file">
                                              <div class="file__content">
                                                  <div class="file__content-left">
                                                      <svg
                                                          width="38"
                                                          height="38"
                                                          viewBox="0 0 38 38"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                          <path
                                                              d="M32.458 9.5h-7.916a.793.793 0 0 1-.792-.792V.792A.792.792 0 0 0 22.958 0H7.125A2.378 2.378 0 0 0 4.75 2.375v33.25A2.378 2.378 0 0 0 7.125 38h23.75a2.378 2.378 0 0 0 2.375-2.375V10.292a.792.792 0 0 0-.792-.792Zm-.791 26.125a.793.793 0 0 1-.792.792H7.125a.793.793 0 0 1-.792-.792V2.375c0-.435.355-.792.792-.792h15.042v7.125a2.378 2.378 0 0 0 2.375 2.375h7.125v24.542Z"
                                                              fill="#fff"
                                                          />
                                                          <path
                                                              d="m33.017 9.733-9.5-9.5a.792.792 0 0 0-1.12 1.119l9.5 9.5a.796.796 0 0 0 1.12 0 .792.792 0 0 0 0-1.12Z"
                                                              fill="#fff"
                                                          />
                                                      </svg>
                                                  </div>
                                                  <div class="file__content-right">
                                                      <div class="file__name">${file.name}</div>
                                                      <div class="file__size">${this._getFileSize(file.size)}</div>
                                                  </div>
                                              </div>
                                              <button
                                                  type="button"
                                                  class="underline-v2 file__remove-btn"
                                                  @click="${() => this._removeFileById(file.id)}"
                                              >
                                                  Удалить файл
                                              </button>
                                          </div>
                                      </li>
                                  `,
                              )}
                          </ul>
                      `
                    : ''}
                <slot></slot>
            </div>
        `;
    }

    _onInputChange(event: any) {
        const inputFiles = Array.from(event.target.files) as File[];

        inputFiles.forEach((file) => {
            try {
                this.uppy.addFile({
                    source: 'file input',
                    name: file.name,
                    type: file.type,
                    data: file,
                });
            } catch (err: any) {
                if (err.isRestriction) {
                    console.error('Restriction error:', err);
                }

                throw err;
            }
        });
    }

    _removeFileById(id: string) {
        this.uppy.removeFile(id);
        this.files = this.files.filter((file) => file.id !== id);
    }

    _getFileSize(fileSizeInBytes: number) {
        let measure = '';
        let power = 0;

        if (fileSizeInBytes < 1024) {
            measure = 'b';
            power = 0;
        } else if (fileSizeInBytes < 1024 ** 2) {
            measure = 'kb';
            power = 1;
        } else if (fileSizeInBytes < 1024 ** 3) {
            measure = 'mb';
            power = 2;
        } else if (fileSizeInBytes < 1024 ** 4) {
            measure = 'gb';
            power = 3;
        } else if (fileSizeInBytes < 1024 ** 5) {
            measure = 'tb';
            power = 4;
        }

        return (
            (fileSizeInBytes / 1024 ** power).toLocaleString('us', { maximumFractionDigits: 1 }).replace(',', '.') +
            measure
        );
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-upload': Upload;
    }
}

customElements.define('app-upload', Upload);

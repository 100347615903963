import gsap from 'gsap';
import pageTransition from '../animations/page-transition';
import { CustomEase } from "gsap/CustomEase";
import timeout from '../utils/timeout';
import ScrollTrigger from 'gsap/ScrollTrigger';

function contentAnimation(next) {

    const duration = 0.3;
    const ease = CustomEase.create("custom", "M0,0 C0.25,0.25 0,1 1,1 ");
    const header = document.querySelector('.a-header');
    const headerItems = document.querySelectorAll('.a-header-item');
    const burgerBtn = document.querySelectorAll('.a-burger');

    // Page variables
    const asideNav = next.container.querySelectorAll('.a-aside-nav');
    const panels = next.container.querySelector('.a-panels');
    const indexTitle = next.container.querySelector('.a-trademark-title');
    const indexSubtitle = next.container.querySelector('.a-trademark-subtitle');
    const indexDescr = next.container.querySelector('.a-trademark-descr');
    const indexScroll = next.container.querySelector('.a-trademark-section-scroll');

    const tl = gsap.timeline({
        onComplete: () => {
            if (typeof onComplete === 'function') {
                onComplete();
            }
            tl.kill();
        }
    });

    tl.set(burgerBtn, {
        scaleX: 0
    })
        .fromTo(header, {
            translateY: '-100%',
            duration,
            ease
        },
        {
            translateY: 0,
            duration,
            ease
        },0)
        .to(headerItems,
            {
                translateY: 0,
                opacity: 1,
                duration,
                ease,
                stagger: 0.1
            })
        .to(burgerBtn,{
            duration,
            scaleX: 1,
            ease,
        }, 0.8)
    // Insert your page animations after this
        .from(indexTitle, {
            opacity: 0,
            y: 10,
            duration,
            ease
        }, 0.8)
        .from(indexSubtitle, {
            opacity: 0,
            y: 10,
            duration,
            ease
        }, 0.85)
        .from(indexDescr, {
            opacity: 0,
            y: 10,
            duration,
            ease
        }, 0.9)
        .from(panels, {
            opacity: 0,
            duration,
            ease
        }, 1)
        .from(indexScroll, {
            opacity: 0,
            duration,
            ease
        }, `-=${duration/2}`)
        .from(asideNav, {
            opacity: 0,
            y: 20,
            duration,
            ease
        }, `-=${duration/2}`)
}


export default {
    name: 'trademark page transition',
    to: {
        namespace: 'trademark-page'
    },

    async leave() {
        const done = this.async();

        pageTransition();
        window.scrollTo({ top: 0, behavior: 'auto' });
        await timeout(600);
        done();
    },

    async enter({next}) {
        window.scrollTo({ top: 0, behavior: 'auto' });
        contentAnimation(next);
    },

    async after() {
        const scrollTriggers = ScrollTrigger.getAll();
        if (scrollTriggers && scrollTriggers.length > 0) {
            scrollTriggers.forEach(st => {
                st.refresh();
            })
        }
    },

    async once({next}) {
        window.scrollTo({ top: 0, behavior: 'auto' });
        contentAnimation(next);
    },

}

export const fetchAppSlider = async () =>
    import('./components/app-slider' /* webpackChunkName: "AppSlider" */).then((m) => m.default);

export const fetchAjaxFormSender = () =>
    import(
        '../modules/ajax-form-sender'
        /* webpackChunkName: "ajax-form-sender" */
    );

export const fetchValidator = () =>
    import(
        '../modules/validator'
        /* webpackChunkName: "validator" */
    );

export const fetchIMask = async () => import('imask' /* webpackChunkName: "imask" */).then((m) => m.default);
